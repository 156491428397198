import React from "react"
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { format } from 'date-fns'

import Page from '../layouts/page'
import Header from '../components/layout/header'
import Title from '../components/layout/title'

import { Link } from 'gatsby'

export default ({ data }) => (
  <Page>
    <Helmet
      bodyAttributes={{
        class: 'bg-grey'
      }}
    >
      <html lang="en" />

      <title>Blog | Nathan Dunn - Software Developer, Consultant and Speaker</title>

      <meta name="description" content="Nathan Dunn is a software developer, consultant and speaker based in Gateshead, UK." />
    </Helmet>

    <Header>
      <Title>Blog</Title>
    </Header>

    <section itemtype="http://schema.org/Blog" itemscope>
      {data.posts.edges.map(post => (
        <article
          key={post.node.frontmatter.slug}
          className="mb-5"
          itemProp="blogPost"
          itemscope
          itemtype="http://schema.org/BlogPosting"
        >
          <header className="font-heading mb-4">
            <h1 className="text-3xl font-bold text-olive leading-tight">
              <Link to={`/blog/${post.node.frontmatter.slug}`}>
                {post.node.frontmatter.title}
              </Link>
            </h1>
            <span className="text-olive text-opacity-75 font-semibold">
              Posted <time dateTime={post.node.frontmatter.date} itemProp="datePublished">{format(new Date(post.node.frontmatter.date), 'do MMMM yyyy')}</time>
            </span>
          </header>

          <p>{post.node.frontmatter.summary}</p>
        </article>
      ))}
    </section>
  </Page>
)

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            summary
            date
          }
        }
      }
    }
  }
`